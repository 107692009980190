/* CSS for the landing page */
body {
    font-family: "Inter", sans-serif;
    margin: 0;
    padding: 0;
  }
  
  .screen {
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 8px 16px;
  }
  
  .top-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    max-width: 1425px;
    position: relative;
  }
  
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 32px;
    width: 100%;
  }
  
  .logo {
    height: 24px;
    width: 408.5px;
  }
  
  .nav-links {
    display: flex;
    align-items: center;
    gap: 24px;
  }
  
  .nav-link {
    color: #111111;
    font-family: "Inter-Medium", Helvetica;
    font-size: 15.6px;
    font-weight: 500;
    letter-spacing: -0.13px;
    line-height: 24px;
    cursor: pointer;
  }
  
  .signup-button {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #111111;
    border-radius: 16px;
    padding: 10px 16px;
    cursor: pointer;
    border: none;
  }
  
  .signup-text {
    color: #ffffff;
    font-family: "Inter-Medium", Helvetica;
    font-size: 15.6px;
    font-weight: 500;
    letter-spacing: -0.13px;
    line-height: 24px;
  }
  
  .main-heading-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 24px 120px;
    width: 100%;
  }
  
  .heading-text-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  .main-heading {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .heading-part {
    color: #212121;
    font-family: "Inter-Bold", Helvetica;
    font-size: 56px;
    font-weight: 700;
    letter-spacing: -2.24px;
    line-height: 64px;
  }
  
  .highlight {
    color: #ee5a29;
  }
  
  .sub-heading {
    display: flex;
    gap: 8px;
  }
  
  .sub-heading p {
    color: #666666;
    font-family: "Inter-Regular", Helvetica;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: -0.66px;
    line-height: 34px;
  }
  
  .start-channel-button {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #212121;
    border-radius: 12px;
    padding: 16px 28px;
    border: none;
    cursor: pointer;
  }
  
  .button-text {
    color: #ffffff;
    font-family: "Inter-SemiBold", Helvetica;
    font-size: 19.8px;
    font-weight: 600;
    letter-spacing: -0.6px;
    line-height: 30px;
  }
  
  .media-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 798px;
    width: 100%;
    position: relative;
  }
  
  .media-image-container {
    display: flex;
    flex-direction: column;
    height: 796px;
    width: 1425px;
    position: relative;
  }
  
  .background-image {
    background-color: #ffe6c9;
    height: 605px;
    width: 1425px;
    position: absolute;
    top: 193px;
    left: 0;
  }
  
  .media-image {
    background-color: var(--gray-6);
    border: 3.31px solid;
    border-color: #212121;
    border-radius: 19.85px;
    box-shadow: 13.23px 13.23px 0px #000000;
    height: 623px;
    width: 1035px;
    position: absolute;
    top: 0;
    left: 194px;
  }
  
  .content-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    max-width: 1136px;
  }
  
  .section-heading {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  .section-heading-text {
    color: #212121;
    font-family: "Inter-Bold", Helvetica;
    font-size: 42px;
    font-weight: 700;
    letter-spacing: -1.82px;
    line-height: 42px;
    width: fit-content;
  }
  
  .content-cards {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  .card-container {
    display: flex;
    gap: 24px;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
  }

  
  .card {
    display: flex;
    flex-direction: row;
    gap: 24px;
    background-color: #ffffff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 12px 24px -5px rgba(0, 0, 0, 0.1), 0 5px 10px -6px rgba(0, 0, 0, 0.05);
    padding: 24px;
    width: 100%;
    max-width: 568px;
  }
  
  .card-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 337.15px;
    background-color: #ffffff01;
    border: 2px solid;
    border-color: #212121;
    border-radius: 12px;
    box-shadow: 8px 8px 0px #000000;
    overflow: hidden;
  }
  
  .card-image-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: var(--backgroundcontainer);
    border-radius: 8px;
    padding: 40px 80px;
  }
  
  .card-image-icons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40.28px;
    background-color: var(--backgroundcontainer);
    border-radius: 16.11px;
  }
  
  .card-image-icon {
    background-color: #ffffff;
    border: 2.01px solid;
    border-color: var(--gray-5);
    border-radius: 8.06px;
    height: 72.5px;
    width: 72.5px;
  }
  
  .convert-icon {
    height: 32.22px !important;
    width: 32.22px !important;
  }
  
  .stripe-icon {
    height: 72.5px;
    width: 72.5px;
  }
  
  .card-content-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .card-heading-image {
    height: 22.3px;
    width: 148px;
  }
  
  .card-heading {
    color: #212121;
    font-family: "Inter-Bold", Helvetica;
    font-size: 37.3px;
    font-weight: 700;
    letter-spacing: -0.95px;
    line-height: 36px;
    width: fit-content;
  }
  
  .card-description {
    display: flex;
    flex-direction: column;
    gap: 8.9px;
  }
  
  .card-description p {
    color: #666666;
    font-family: "Inter-Regular", Helvetica;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: -0.6px;
    line-height: 30px;
  }
  
  .card-action-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .card-action-item {
    display: flex;
    gap: 4px;
    align-items: center;
  }
  
  .card-action-text {
    color: #666666;
    font-family: "Inter-Medium", Helvetica;
    font-size: 15.9px;
    font-weight: 500;
    letter-spacing: -0.48px;
    line-height: 18px;
    margin-top: -1px;
  }
  
  .figma-icon {
    height: 24px !important;
    width: 24px !important;
  }
  
  .card-action {
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: #212121;
    border-radius: 8px;
    padding: 8px 20px;
    border: none;
    cursor: pointer;
  }
  
  .card-video {
    background-color: var(--gray-6);
    border: 1px solid;
    border-color: #0000001f;
    border-radius: 9.6px;
    box-shadow: 0px 18px 36px -6px #0000000f, 0px 6px 12px -6px #0000000f;
    height: 400px;
    width: 450px;
    position: relative;
    top: 2px;
    left: 2px;
  }
  
  .photoshop-icon,
  .sketch-icon,
  .xd-icon,
  .html-icon,
  .css-icon,
  .js-icon {
    height: 32px !important;
    width: 32px !important;
  }
  
  .section-content-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  .section-content-heading {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .section-content-cards {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
  
  @media (max-width: 768px) {
    .top-container {
      flex-direction: column;
      padding: 16px;
    }
  
    .header-container {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .nav-links {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .nav-link {
      margin-bottom: 12px;
    }
  
    .signup-button {
      margin-top: 12px;
    }
  
    .main-heading-container {
      padding: 24px 16px;
    }
  
    .heading-part {
      font-size: 28px;
      line-height: 34px;
      letter-spacing: -1px;
    }
  
    .sub-heading p {
      font-size: 16px;
      line-height: 26px;
      letter-spacing: -0.48px;
    }
  
    .start-channel-button {
      margin-top: 12px;
    }
  
    .media-container {
      height: 400px;
    }
  
    .background-image {
      height: 250px;
      top: 50px;
    }
  
    .media-image {
      height: 250px;
      top: 50px;
      left: 16px;
    }
  

  
    .card-image-container {
      height: 200px;
    }
  
    .card-image-wrap {
      padding: 20px 40px;
    }
  
    .card-image-icons {
      flex-direction: column;
      gap: 8px;
    }
  
    .card-heading {
      font-size: 24px;
      line-height: 28px;
      letter-spacing: -0.5px;
    }
  
    .card-description p {
      font-size: 14px;
      line-height: 22px;
      letter-spacing: -0.42px;
    }
  
    .card-action-container {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .card-action-item {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .card-action-text {
      margin-top: 0;
    }
  
    .card-video {
      height: 200px;
      width: 100%;
    }
  }